import { PracticeScoreDto, PracticeScoreListRes } from "models/classes/practiceScore.class"; // Changed import to practiceScore
import { createContext, PropsWithChildren, useContext, useState } from "react";

type State = {
  practiceScoreList: PracticeScoreListRes | null;
  practiceScoreDetail: PracticeScoreDto | null;
};

type Actions = {
  updatePracticeScoreList: (data: PracticeScoreListRes) => void;
  updatePracticeScoreDetail: (data: PracticeScoreDto | null) => void;
};

const initState: State = {
  practiceScoreList: null,
  practiceScoreDetail: null,
};

const defaultActions: Actions = {
  updatePracticeScoreList: () => { },
  updatePracticeScoreDetail: () => { },
};

const PracticeScoreContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const PracticeScoreProvider = ({ children }: PropsWithChildren<{}>) => {
  const [practiceScoreList, setPracticeScoreList] = useState<PracticeScoreListRes | null>(null);
  const [practiceScoreDetail, setPracticeScoreDetail] = useState<PracticeScoreDto | null>(null);

  const updatePracticeScoreList = (data: PracticeScoreListRes) => {
    setPracticeScoreList(data);
  };

  const updatePracticeScoreDetail = (data: PracticeScoreDto | null) => {
    setPracticeScoreDetail(data);
  }

  return (
    <PracticeScoreContext.Provider
      value={{
        practiceScoreList,
        updatePracticeScoreList,
        practiceScoreDetail,
        updatePracticeScoreDetail,
      }}
    >
      {children}
    </PracticeScoreContext.Provider>
  );
};

export const usePracticeScoreContext = () => useContext(PracticeScoreContext);
