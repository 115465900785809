import { PracticeSubjectData } from "models/classes/practiceSubject.class";
import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { FetchPracticeSubjectParams } from "./practiceSubjectParam";

export const fetchPracticeSubjectListApi = async ({
  newAbortSignal,
  searchName,
  page,
  pageSize,
  ...params
}: FetchPracticeSubjectParams) =>
  publicRequest.get(
    `/practiceSubjects/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchPracticeSubjectCreateApi = async (
  body: PracticeSubjectData
) => publicRequest.post(`/practiceSubjects/create`, body) as any;

export const fetchPracticeSubjectUpdateApi = async (
  id: number,
  body: PracticeSubjectData
) => publicRequest.put(`/practiceSubjects/update/${id}`, body) as any;

export const fetchPracticeSubjectDetailApi = async ({
  practiceSubjectId,
  newAbortSignal,
  ...params
}: FetchPracticeSubjectParams) =>
  publicRequest.get(
    `/practiceSubjects/detail/${practiceSubjectId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchPracticeSubjectDeleteApi = async ({
  practiceSubjectId,
}: FetchPracticeSubjectParams) =>
  publicRequest.delete(`/practiceSubjects/delete/${practiceSubjectId}`) as any;
