import React, { useEffect, useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from './reportWebVitals';

// CSS & COMPONENTS
import App from './App';
import './index.css';

// CONTEXTS
import SnackbarItem from "views/SnackbarItem";

// MUI
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AnswerProvider } from "apis/answerApis/answerContext";
import { AuthProvider } from "apis/authApis/authContext";
import { CourseBuyerProvider } from "apis/courseBuyerApis/courseBuyerContext";
import { CourseCategoryProvider } from "apis/courseCategoryApis/courseCategoryContext";
import { CourseDescriptionProvider } from "apis/courseDescriptionApis/courseDescriptionContext";
import { CourseLessonProvider } from "apis/courseLessonApis/courseLessonContext";
import { CoursePersonalProvider } from "apis/coursePersonalApis/coursePersonalContext";
import { CourseSectionProvider } from "apis/courseSectionApis/courseSectionContext";
import { InterviewCategoryProvider } from "apis/interviewCategoryApis/interviewCategoryContext";
import { QuestionProvider } from "apis/questionApis/questionContext";
import { RouteProvider } from "store/routeContext";
import { SnackbarProvider } from "store/snackbarContext";
import { ThemeCustomizeProvider, useThemeContext } from "store/themeContext";
import { ThemeProvider } from 'styled-components';
import { DARK_THEME, WHITE } from "globals/enums/common.enum";
import { PracticeProvider } from "apis/practiceApis/practiceContext";
import { PracticeSubjectProvider } from "apis/practiceSubjectApis/practiceSubjectContext";
import { PracticeQuestionProvider } from "apis/practiceQuestionApis/practiceQuestionContext";
import { PracticeAnswerProvider } from "apis/practiceAnswerApis/practiceAnswerContext";
import { PracticeScoreProvider } from "apis/practiceScoreApis/practiceScoreContext";

function MyApp() {
  const { theme, activeTheme } = useThemeContext();

  useLayoutEffect(() => {
    // Slide
    const linkSlider: HTMLLinkElement = document.createElement("link");
    linkSlider.rel = 'stylesheet';
    linkSlider.type = 'text/css';
    linkSlider.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css';
    document.head.appendChild(linkSlider);

    // Slide Theme
    const linkSliderTheme: HTMLLinkElement = document.createElement("link");
    linkSliderTheme.rel = 'stylesheet';
    linkSliderTheme.type = 'text/css';
    linkSliderTheme.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css';
    document.head.appendChild(linkSliderTheme);
  }, []);

  useLayoutEffect(() => {
    if (activeTheme == DARK_THEME) {
      document.documentElement.style.backgroundColor = '#191b27';
    } else {
      document.documentElement.style.backgroundColor = WHITE;
    }
  }, [activeTheme]);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <RouteProvider>
            <SnackbarProvider>
              <CoursePersonalProvider>
                <CourseCategoryProvider>
                  <CourseLessonProvider>
                    <CourseBuyerProvider>
                      <CourseSectionProvider>
                        <CourseDescriptionProvider>
                          <InterviewCategoryProvider>
                            <QuestionProvider>
                              <AnswerProvider>
                                <PracticeProvider>
                                  <PracticeSubjectProvider>
                                    <PracticeQuestionProvider>
                                      <PracticeAnswerProvider>
                                        <PracticeScoreProvider>
                                          <App />
                                        </PracticeScoreProvider>
                                      </PracticeAnswerProvider>
                                    </PracticeQuestionProvider>
                                  </PracticeSubjectProvider>
                                </PracticeProvider>
                                <SnackbarItem />
                              </AnswerProvider>
                            </QuestionProvider>
                          </InterviewCategoryProvider>
                        </CourseDescriptionProvider>
                      </CourseSectionProvider>
                    </CourseBuyerProvider>
                  </CourseLessonProvider>
                </CourseCategoryProvider>
              </CoursePersonalProvider>
            </SnackbarProvider>
          </RouteProvider>
        </AuthProvider>
      </ThemeProvider>
    </MuiThemeProvider >
  )
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.Fragment>
    <ThemeCustomizeProvider>
      <MyApp />
    </ThemeCustomizeProvider>
  </React.Fragment>
);

reportWebVitals();