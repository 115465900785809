import React, { useState } from "react";

// CONSTANTS
import { Lang } from "globals/constants/i18n";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";

// FUNCTIONS
import { changeLanguage } from "services/localeService";
import { getLangCode } from "locales";

// ICONS
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from "react-i18next";
import DialogSlide from "components/modals/DialogSlide";
import styled from "styled-components";
import { useThemeContext } from "store/themeContext";
import customTheme from "theme/theme";
import { colorDark, colorLight } from "theme/mode";
import { DARK_THEME, LIGHT_THEME, WHITE } from "globals/enums/common.enum";

const HeaderLanguage = React.memo(() => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { activeTheme, updateTheme } = useThemeContext();

  const [isSetting, setSetting] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<Lang>(getLangCode())

  const handleChangeLanguage = (language: Lang) => {
    setCurrentLanguage(language);
    changeLanguage(language);
  };

  return (
    <Stack flexDirection="row" justifyContent="center" alignItems="center" columnGap="1.5rem">
      <ButtonStyled
        columnGap={{ xs: '.75rem', md: '1rem' }}
        padding={{ xs: '.5rem 1rem', md: '.75rem 1.25rem' }}
        className="bg-main"
        onClick={() => setSetting(true)}
      >
        <SettingsIcon style={{ color: WHITE }} />
      </ButtonStyled>

      <DialogSlide
        open={isSetting}
        isClose={false}
        isConfirm={false}
        onClose={(value) => setSetting(value)}
        onConfirm={() => setSetting(false)}
      >
        <Stack
          flexDirection="column"
          color={theme.palette.text.light}
          rowGap={{ xs: '3rem' }}
        >
          <Stack flexDirection={{ xs: 'column' }} rowGap={{ xs: '1.5rem' }}>
            <Box color={theme.palette.text.light} fontWeight={500}>{t('Language')}</Box>
            <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '1.5rem' }}>
              <ButtonStyled
                columnGap={{ xs: '.75rem', md: '1rem' }}
                padding={{ xs: '.5rem 1rem', md: '.75rem 1.25rem' }}
                width={100}
                onClick={() => handleChangeLanguage('vi')}
                className={currentLanguage === 'vi' ? 'bg-main' : 'bg-disable'}
              >
                <Box color={WHITE}>Vietnamese</Box>
              </ButtonStyled>
              <ButtonStyled
                columnGap={{ xs: '.75rem', md: '1rem' }}
                padding={{ xs: '.5rem 1rem', md: '.75rem 1.25rem' }}
                width={100}
                onClick={() => handleChangeLanguage('en')}
                className={currentLanguage === 'en' ? 'bg-main' : 'bg-disable'}
              >
                <Box color={WHITE}>English</Box>
              </ButtonStyled>
            </Stack>
          </Stack>
          <Stack flexDirection={{ xs: 'column' }} rowGap={{ xs: '1.5rem' }}>
            <Box color={theme.palette.text.light} fontWeight={500}>{t('Theme')}</Box>
            <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '1.5rem' }}>
              <ButtonStyled
                columnGap={{ xs: '.75rem', md: '1rem' }}
                padding={{ xs: '.5rem 1rem', md: '.75rem 1.25rem' }}
                width={100}
                onClick={() => updateTheme(customTheme(colorDark), DARK_THEME)}
                className={activeTheme == DARK_THEME ? 'bg-main' : 'bg-disable'}
              >
                <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '1rem' }} alignItems={{ xs: 'center' }}>
                  <DarkModeIcon style={{ color: WHITE }} />
                  <Box color={WHITE}>{t('Dark')}</Box>
                </Stack>
              </ButtonStyled>
              <ButtonStyled
                columnGap={{ xs: '.75rem', md: '1rem' }}
                padding={{ xs: '.5rem 1rem', md: '.75rem 1.25rem' }}
                width={100}
                onClick={() => updateTheme(customTheme(colorLight), LIGHT_THEME)}
                className={activeTheme == LIGHT_THEME ? 'bg-main' : 'bg-disable'}
              >
                <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '1rem' }} alignItems={{ xs: 'center' }}>
                  <LightModeIcon style={{ color: WHITE }} />
                  <Box color={WHITE}>{t('Light')}</Box>
                </Stack>
              </ButtonStyled>
            </Stack>
          </Stack>
        </Stack>
      </DialogSlide>
    </Stack>
  );
});

const ButtonStyled = styled(Stack)`
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export default HeaderLanguage;
