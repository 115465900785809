import useRoute from "hooks/useRoute";
import { Suspense, lazy } from "react";
import styled from "styled-components";

// HOOKS
import { usePracticeScoreTopRankByApi } from "apis/practiceScoreApis/usePracticeScoreApis";
import { usePracticeSubjectDetailByApi } from "apis/practiceSubjectApis/usePracticeSubjectApis";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// COMPONENTS
import { Stack } from "@mui/material";
import LoadingWrapper from "components/progressBar/LoadingWrapper";
const BannerLayout = lazy(() => import("layouts/BannerLayout"));
const PracticeSubjectContent = lazy(() => import("./sections/PracticeSubjectContent"));
const PracticeSubjectBody = lazy(() => import("./sections/PracticeSubjectBody"));

const PracticeChoiceDetailPage = () => {
  // instances
  const { params: { id: practiceSubjectId } } = useRoute();

  const { practiceSubjectDetail } = usePracticeSubjectDetailByApi({ practiceSubjectId });
  const { topRankScore } = usePracticeScoreTopRankByApi({ practiceSubjectId });

  return (
    <LoadingWrapper isLoading={!practiceSubjectDetail?.id}>
      <WrapperStyled
        padding={{ xs: '2rem 1rem', sm: '3rem 2rem' }}
        width={{ xs: 'auto', md: '100%' }}
        flexDirection={{ xs: 'column' }}
        rowGap={{ xs: '3rem' }}
      >
        {practiceSubjectDetail?.name && (
          <Suspense>
            <BannerLayout
              height={{ xs: '10rem', md: '12rem' }}
              title="Practice detail"
              subTitle="Choose your favorite practice!"
              imageFile={CharacterBanner}
              widthImage={{ xs: '150px', md: '200px' }}
              heightImage={{ xs: '150px' }}
            />
            <PracticeSubjectContent practiceSubjectDetail={practiceSubjectDetail!} />
            <PracticeSubjectBody
              subjectImage={practiceSubjectDetail?.image!}
              subjectName={practiceSubjectDetail?.name}
              topRankScore={topRankScore}
            />
          </Suspense>
        )}
      </WrapperStyled>
    </LoadingWrapper>
  );
};

const WrapperStyled = styled(Stack)`
`;


export default PracticeChoiceDetailPage;
