import React, { useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// HOOKS
import { useAuthContext } from "apis/authApis/authContext";
import useRoute from "hooks/useRoute";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";

// CONSTANTS
import { PageRouteName } from "globals/enums/routes.enum";

// ICONS
import { baseBlogURL } from "apis/configs/requestApis";
import InterviewIcon from 'assets/ic-interview.png';
import CourseIcon from 'assets/ic-course.png';
import PracticeIcon from 'assets/ic-practice.png';
import DashboardIcon from 'assets/ic-dashboard.png';
import SignInIcon from 'assets/ic-login.png';
import PowerIcon from 'assets/ic-power.png';
import UserIcon from 'assets/ic-user.png';
import CodeIcon from 'assets/ic-coding.png';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

// MUI ICONS
import MuiDashboardIcon from '@mui/icons-material/Dashboard';
import MuiSchoolIcon from '@mui/icons-material/School';
import MuiQuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MuiCodeIcon from '@mui/icons-material/Code';
import MuiLoginIcon from '@mui/icons-material/Login';
import MuiAccountCircleIcon from '@mui/icons-material/AccountCircle';
import MuiLogoutIcon from '@mui/icons-material/Logout';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { useThemeContext } from "store/themeContext";
import { DARK_THEME, WHITE } from "globals/enums/common.enum";

type MenuItem = {
  id: number;
  route: PageRouteName;
  name: string;
  image: string;
  alt: string;
  iconDark?: any,
  iconLight?: any,
  subMenu?: PageRouteName[];
};

const HeaderItem = React.memo(() => {
  // instances
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname, redirect } = useRoute();

  // hooks
  const { token } = useAuthContext();
  const { activeTheme } = useThemeContext();

  // state
  const [currentRoute, setCurrentRoute] = useState<PageRouteName>(PageRouteName.DashboardPage);

  // functions
  const handleRedirectPage = (route: PageRouteName) => {
    if (route === PageRouteName.BlogPage) {
      window.location.href = `${baseBlogURL}`;
      return;
    }
    setCurrentRoute(route);
    redirect(route);
  };

  // memos
  const MenuClientItems: MenuItem[] = useMemo(() => [
    {
      id: 1,
      route: PageRouteName.DashboardPage,
      name: 'Dashboard',
      image: DashboardIcon,
      iconDark: <MuiDashboardIcon style={{ color: theme.palette.text.light }} />,
      iconLight: <MuiDashboardIcon style={{ color: WHITE }} />,
      alt: 'Trang chủ - Ninedev'
    },
    {
      id: 2,
      route: PageRouteName.CourseStorePage,
      name: 'Course',
      image: CourseIcon,
      alt: 'Khoá học - Ninedev',
      iconDark: <MuiSchoolIcon style={{ color: theme.palette.text.light }} />,
      iconLight: <MuiSchoolIcon style={{ color: WHITE }} />,
      subMenu: [
        PageRouteName.CourseCategoryStorePage,
        PageRouteName.CourseViewPage,
      ],
    },
    {
      id: 3,
      route: PageRouteName.PracticeChoicePage,
      name: 'Lightning Fast',
      image: PracticeIcon,
      alt: 'Luyện tập - Ninedev',
      iconDark: <RocketLaunchIcon style={{ color: theme.palette.text.light }} />,
      iconLight: <RocketLaunchIcon style={{ color: WHITE }} />,
      subMenu: [
        PageRouteName.PracticeChoiceDetailPage,
        PageRouteName.PracticeChoiceStartPage,
        PageRouteName.PracticeChoiceResultPage,
      ],
    },
    {
      id: 4,
      route: PageRouteName.InterviewPage,
      name: 'Interview',
      image: InterviewIcon,
      alt: 'Top câu hỏi phỏng vấn - Ninedev',
      iconDark: <MuiQuestionAnswerIcon style={{ color: theme.palette.text.light }} />,
      iconLight: <MuiQuestionAnswerIcon style={{ color: WHITE }} />,
      subMenu: [
        PageRouteName.InterviewDetailPage
      ]
    },
    {
      id: 5,
      route: PageRouteName.ProjectPage,
      name: 'Project',
      image: CodeIcon,
      iconDark: <MuiCodeIcon style={{ color: theme.palette.text.light }} />,
      iconLight: <MuiCodeIcon style={{ color: WHITE }} />,
      alt: 'Dự án - Ninedev',
      subMenu: [
        PageRouteName.ProjectDetailPage
      ]
    },
    {
      id: 6,
      route: token ? PageRouteName.ProfilePage : PageRouteName.SignInPage,
      name: token ? 'Account' : 'Sign In',
      image: token ? UserIcon : SignInIcon,
      iconDark: token
        ? <MuiAccountCircleIcon style={{ color: theme.palette.text.light }} />
        : <MuiLoginIcon style={{ color: theme.palette.text.light }} />,
      iconLight: token
        ? <MuiAccountCircleIcon style={{ color: WHITE }} />
        : <MuiLoginIcon style={{ color: WHITE }} />,
      alt: 'Tài khoản - Ninedev',
      subMenu: [
        PageRouteName.ProfileInformationPage,
        PageRouteName.ProfilePasswordPage,
        PageRouteName.ProfilePurchasedPage,
        PageRouteName.ProfileLessonPage,
        PageRouteName.ProfileSallerPage,
        PageRouteName.ProfileSectionPage,
        PageRouteName.ProfileDescriptionPage,
        PageRouteName.ProfileManageStudentPage,
        PageRouteName.ProfileUserPage,
        PageRouteName.ProfileCategoriesPage,
        PageRouteName.ProfileInterviewPage,
        PageRouteName.ProfileQuestionPage,
        PageRouteName.ProfileAnswerPage,
      ]
    },
  ], [token, activeTheme]);

  const MenuLogoutItem: MenuItem = useMemo(() => {
    return {
      id: Number(MenuClientItems.length) + 1,
      route: PageRouteName.LogoutPage,
      name: 'Log out',
      image: PowerIcon,
      iconDark: <MuiLogoutIcon style={{ color: theme.palette.text.light }} />,
      iconLight: <MuiLogoutIcon style={{ color: WHITE }} />,
      alt: 'Đăng xuất'
    }
  }, [token, activeTheme]);

  // effects
  useLayoutEffect(() => {
    setCurrentRoute(pathname as PageRouteName);
  }, [pathname]);

  const renderMenuItem = (item: MenuItem) => (
    <Stack
      key={item.id}
      flexDirection={{ xs: 'row' }}
      columnGap={{ xs: '.75rem', md: '1rem' }}
      alignItems={{ xs: 'center' }}
      padding={{ xs: '.5rem .75rem', md: '.75rem 1.5rem', }}
      borderRadius={{ xs: '10px' }}
      style={{ cursor: 'pointer' }}
      className={item.route === currentRoute || (item.subMenu?.length && item.subMenu.some(item => currentRoute.includes(item))) ? 'bg-main' : ''}
      onClick={() => handleRedirectPage(item.route)}
    >
      {activeTheme == DARK_THEME
        ? item.iconDark
        : item.route === currentRoute || (item.subMenu?.length && item.subMenu.some(item => currentRoute.includes(item))) ? item.iconLight : item.iconDark}
      <Box
        className="menu-active"
        fontSize={{ xs: 14, md: 16 }}
        color={{
          xs: activeTheme == DARK_THEME
            ? theme.palette.text.light
            : item.route === currentRoute || (item.subMenu?.length && item.subMenu.some(item => currentRoute.includes(item))) ? WHITE : theme.palette.text.light
        }}
        fontWeight={{ xs: '400' }}
      >
        {t(item.name)}
      </Box>
    </Stack>
  )

  return (
    <Stack
      pt={{ xs: '1rem', md: '2rem' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '.5rem', md: '1rem' }}
    >
      {MenuClientItems.map(item => renderMenuItem(item))}
      {token && renderMenuItem(MenuLogoutItem)}
    </Stack>
  );
});

export default HeaderItem;