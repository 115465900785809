import { PracticeSubjectDto, PracticeSubjectListRes } from "models/classes/practiceSubject.class"; // Changed import to practiceSubject
import { createContext, PropsWithChildren, useContext, useState } from "react";

type State = {
  practiceSubjectList: PracticeSubjectListRes | null;
  practiceSubjectDetail: PracticeSubjectDto | null;
};

type Actions = {
  updatePracticeSubjectList: (data: PracticeSubjectListRes) => void;
  updatePracticeSubjectDetail: (data: PracticeSubjectDto) => void;
};

const initState: State = {
  practiceSubjectList: null,
  practiceSubjectDetail: null,
};

const defaultActions: Actions = {
  updatePracticeSubjectList: () => { },
  updatePracticeSubjectDetail: () => { },
};

const PracticeSubjectContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const PracticeSubjectProvider = ({ children }: PropsWithChildren<{}>) => {
  const [practiceSubjectList, setPracticeSubjectList] = useState<PracticeSubjectListRes | null>(null);
  const [practiceSubjectDetail, setPracticeSubjectDetail] = useState<PracticeSubjectDto | null>(null);

  const updatePracticeSubjectList = (data: PracticeSubjectListRes) => {
    setPracticeSubjectList(data);
  };

  const updatePracticeSubjectDetail = (data: PracticeSubjectDto) => {
    setPracticeSubjectDetail(data);
  };

  return (
    <PracticeSubjectContext.Provider
      value={{
        practiceSubjectList,
        practiceSubjectDetail,
        updatePracticeSubjectList,
        updatePracticeSubjectDetail,
      }}
    >
      {children}
    </PracticeSubjectContext.Provider>
  );
};

export const usePracticeSubjectContext = () => useContext(PracticeSubjectContext);
