import { PracticeAnswerDto, PracticeAnswerListRes } from "models/classes/practiceAnswer.class"; // Changed import to practiceAnswer
import { createContext, PropsWithChildren, useContext, useState } from "react";

type State = {
  practiceAnswerList: PracticeAnswerListRes | null;
  practiceAnswerDetail: PracticeAnswerDto | null;
};

type Actions = {
  updatePracticeAnswerList: (data: PracticeAnswerListRes) => void;
  updatePracticeAnswerDetail: (data: PracticeAnswerDto) => void;
};

const initState: State = {
  practiceAnswerList: null,
  practiceAnswerDetail: null,
};

const defaultActions: Actions = {
  updatePracticeAnswerList: () => { },
  updatePracticeAnswerDetail: () => { },
};

const PracticeAnswerContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const PracticeAnswerProvider = ({ children }: PropsWithChildren<{}>) => {
  const [practiceAnswerList, setPracticeAnswerList] = useState<PracticeAnswerListRes | null>(null);
  const [practiceAnswerDetail, setPracticeAnswerDetail] = useState<PracticeAnswerDto | null>(null);

  const updatePracticeAnswerList = (data: PracticeAnswerListRes) => {
    setPracticeAnswerList(data);
  };

  const updatePracticeAnswerDetail = (data: PracticeAnswerDto) => {
    setPracticeAnswerDetail(data);
  };

  return (
    <PracticeAnswerContext.Provider
      value={{
        practiceAnswerList,
        practiceAnswerDetail,
        updatePracticeAnswerList,
        updatePracticeAnswerDetail,
      }}
    >
      {children}
    </PracticeAnswerContext.Provider>
  );
};

export const usePracticeAnswerContext = () => useContext(PracticeAnswerContext);
