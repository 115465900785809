import { Suspense, lazy } from "react";
import styled from "styled-components";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// COMPONENTS
import { Stack } from "@mui/material";
import { useCoursePersonalDetailByApi } from "apis/coursePersonalApis/useCoursePersonalApis";
import LoadingWrapper from "components/progressBar/LoadingWrapper";
import useRoute from "hooks/useRoute";
import CourseComment from "./sections/CourseComment";
const BannerLayout = lazy(() => import("layouts/BannerLayout"));
const CourseContent = lazy(() => import("./sections/CourseContent"));
const CourseBody = lazy(() => import("./sections/CourseBody"));

const CourseDetailPage = () => {
  // instances
  const { params: { id: coursePersonalId } } = useRoute();

  // hooks api
  const { coursePersonalDetail } = useCoursePersonalDetailByApi(coursePersonalId);

  return (
    <LoadingWrapper isLoading={!coursePersonalDetail?.name}>
      <WrapperStyled
        padding={{ xs: '2rem 1rem', sm: '3rem 2rem' }}
        width={{ xs: 'auto', md: '100%' }}
        flexDirection={{ xs: 'column' }}
        rowGap={{ xs: '3rem' }}
      >
        {coursePersonalDetail?.name && (
          <Suspense>
            <BannerLayout
              height={{ xs: '10rem', md: '12rem' }}
              title="Course detail"
              subTitle="Choose your favorite course!"
              imageFile={CharacterBanner}
              widthImage={{ xs: '150px', md: '200px' }}
              heightImage={{ xs: '150px' }}
            />
            <CourseContent coursePersonalDetail={coursePersonalDetail!} />
            <CourseBody coursePersonalDetail={coursePersonalDetail!} />
            <CourseComment coursePersonalId={coursePersonalId} />
          </Suspense>
        )}
      </WrapperStyled>
    </LoadingWrapper>
  );
};

const WrapperStyled = styled(Stack)`
`;


export default CourseDetailPage;
