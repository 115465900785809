import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { PracticeAnswerData } from "models/classes/practiceAnswer.class";
import { FetchPracticeAnswerParams } from "./practiceAnswerParam";

export const fetchPracticeAnswerListApi = async ({
  newAbortSignal,
  searchName,
  page,
  pageSize,
  ...params
}: FetchPracticeAnswerParams) =>
  publicRequest.get(
    `/practiceAnswers/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchPracticeAnswerCreateApi = async (body: PracticeAnswerData) =>
  publicRequest.post(`/practiceAnswers/create`, body) as any;

export const fetchPracticeAnswerUpdateApi = async (
  id: number,
  body: PracticeAnswerData
) => publicRequest.put(`/practiceAnswers/update/${id}`, body) as any;

export const fetchPracticeAnswerDetailApi = async ({
  practiceAnswerId,
  newAbortSignal,
  ...params
}: FetchPracticeAnswerParams) =>
  publicRequest.get(
    `/practiceAnswers/detail/${practiceAnswerId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchPracticeAnswerDeleteApi = async ({
  practiceAnswerId,
}: FetchPracticeAnswerParams) =>
  publicRequest.delete(`/practiceAnswers/delete/${practiceAnswerId}`) as any;

export const fetchPracticeAnswerClientApi = async ({
  newAbortSignal,
  searchName,
  ...params
}: FetchPracticeAnswerParams) =>
  publicRequest.get(
    `/practiceAnswers/client`,
    parseRequestParams(params, { searchName, newAbortSignal }, true)
  ) as any;
