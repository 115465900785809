
import { lazy, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti'

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// COMPONENTS
import { Stack } from "@mui/material";
import { usePracticeScoreDetailByApi } from "apis/practiceScoreApis/usePracticeScoreApis";
import useRoute from "hooks/useRoute";
import LoadingWrapper from "components/progressBar/LoadingWrapper";
import { isEmpty } from "lodash";
const BannerLayout = lazy(() => import("layouts/BannerLayout"));
const ResultContent = lazy(() => import("./sections/ResultContent"));

const PracticeChoiceResultPage = () => {
  const { params: { id: practiceScoreId } } = useRoute();
  const { practiceScoreDetail } = usePracticeScoreDetailByApi({ practiceScoreId });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (practiceScoreDetail && practiceScoreDetail.id && wrapperRef.current) {
      setTimeout(() => {
        setWrapperHeight(wrapperRef.current?.offsetHeight!);
      }, 1000);
    }
  }, [practiceScoreDetail]);

  return (
    <LoadingWrapper isLoading={!practiceScoreDetail?.id}>
      <WrapperStyled
        padding={{ xs: '1.5rem', sm: '2rem' }}
        width={{ xs: 'auto', md: '100%' }}
        flexDirection={{ xs: 'column' }}
        rowGap={{ xs: '2rem' }}
        justifyContent={{ xs: 'space-between' }}
        alignItems={{ xs: 'center' }}
        ref={wrapperRef}
      >
        <Stack>
          <Confetti width={width - 20} height={width > 1024 ? height : wrapperHeight} />
        </Stack>
        <BannerLayout
          height={{ xs: '10rem', md: '12rem' }}
          title="Result"
          subTitle="Congratulations on passing the test"
          imageFile={CharacterBanner}
          widthImage={{ xs: '150px', md: '200px' }}
          heightImage={{ xs: '150px' }}
        />
        <ResultContent
          practiceScoreDetail={practiceScoreDetail!}
        />
      </WrapperStyled>
    </LoadingWrapper>
  );
};

const WrapperStyled = styled(Stack)`
`;


export default PracticeChoiceResultPage;
