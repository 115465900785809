import useAbortRequest from "hooks/useAbortRequest";
import { isEmpty } from "lodash";
import { PracticeScoreDto } from "models/classes/practiceScore.class";
import { useEffect, useState } from "react";
import {
  actionPracticeScoreDetailClientApi,
  actionPracticeScoreListApi,
  actionPracticeScoreListClientApi,
  actionPracticeScoreTopRankApi,
} from "./practiceScoreAction";
import { usePracticeScoreContext } from "./practiceScoreContext";
import { FetchPracticeScoreParams } from "./practiceScoreParam";
import { Roles } from "globals/enums/auth.enum";

export const usePracticeScoreListByApi = ({
  searchName,
  page,
  role,
}: FetchPracticeScoreParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { practiceScoreList, updatePracticeScoreList } =
    usePracticeScoreContext();

  const fetchPracticeScoreListByApi = async () => {
    await abortRequest();
    const data =
      role == Roles.ADMIN
        ? await actionPracticeScoreListApi({
            searchName,
            page,
            newAbortSignal,
          })
        : await actionPracticeScoreListClientApi({
            searchName,
            page,
            newAbortSignal,
          });
    updatePracticeScoreList(data!);
  };

  // Unmount APIs
  useEffect(() => {
    if (isEmpty(practiceScoreList) || searchName || page) {
      fetchPracticeScoreListByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page]);

  return {
    practiceScoreList,
    fetchPracticeScoreListByApi,
  };
};

export const usePracticeScoreTopRankByApi = ({
  practiceSubjectId,
}: FetchPracticeScoreParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const [topRankScore, setTopRankScore] = useState<PracticeScoreDto[]>([]);

  const fetchPracticeScoreTopRankByApi = async () => {
    await abortRequest();
    const data = await actionPracticeScoreTopRankApi({
      practiceSubjectId,
      newAbortSignal,
    });
    setTopRankScore(data!);
  };

  // Unmount APIs
  useEffect(() => {
    fetchPracticeScoreTopRankByApi();
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [practiceSubjectId]);

  return {
    topRankScore,
    fetchPracticeScoreTopRankByApi,
  };
};

export const usePracticeScoreDetailByApi = ({
  practiceScoreId,
}: FetchPracticeScoreParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { practiceScoreDetail, updatePracticeScoreDetail } =
    usePracticeScoreContext();

  const fetchPracticeScoreDetail = async () => {
    await abortRequest();
    const data = await actionPracticeScoreDetailClientApi({
      practiceScoreId,
      newAbortSignal,
    });
    updatePracticeScoreDetail(data!);
  };

  // Unmount APIs
  useEffect(() => {
    if (practiceScoreId) {
      fetchPracticeScoreDetail();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [practiceScoreId]);

  return {
    practiceScoreDetail,
    fetchPracticeScoreDetail,
  };
};
