import { Suspense, lazy } from "react";
import styled from "styled-components";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// COMPONENTS
import { Stack } from "@mui/material";
import SignInForm from "./sections/SignInForm";
const BannerLayout = lazy(() => import("layouts/BannerLayout"));

const SignInPage = () => {
  return (
    <WrapperStyled
      padding={{ xs: '2rem 1rem', sm: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '3rem' }}
    >
      <Suspense>
        <BannerLayout
          height={{ xs: '10rem', md: '12rem' }}
          title="Sign In"
          subTitle="Let's start logging into the system"
          imageFile={CharacterBanner}
          widthImage={{ xs: '120px', md: '200px' }}
          heightImage={{ xs: '120px' }}
        />
        <SignInForm />
      </Suspense>
    </WrapperStyled>
  );
};

const WrapperStyled = styled(Stack)`
`;

export default SignInPage;
