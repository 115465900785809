import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// ICONS
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import IcDrive from 'assets/ic-drive.png';

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import { CourseLessonInfoDto } from "models/classes/courseLesson.class";
import { VideoTypeEnum } from "globals/enums/courses.enum";
import LoadingImage from "components/progressBar/LoadingImage";
import { WHITE } from "globals/enums/common.enum";
import { useWindowSize } from "react-use";

type Props = {
  lesson: CourseLessonInfoDto | null;
}

const CourseViewLesson = React.memo(({ lesson }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { height } = useWindowSize();

  return (
    <WrapperStyled
      width={{ xs: '-webkit-fill-available' }}
      height={{ xs: '100%' }}
      flexDirection="column"
      rowGap={{ xs: '1rem' }}
    >
      <Stack
        padding={{ xs: '1rem 2rem' }}
        className="bg-main"
        borderRadius={3}
        flexDirection={{ xs: 'row' }}
        alignItems={{ xs: 'center' }}
        columnGap={{ xs: '.5rem' }}
      >
        <PlayCircleIcon style={{ color: WHITE }} />
        <Box
          fontSize={{ xs: 18, md: 24 }}
          color={WHITE}
          fontWeight={500}
        >
          {t('Video lesson')}
        </Box>
      </Stack>

      {lesson?.id && (
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '1rem' }}
          height={{ xs: 250, sm: height - 175 }}
          overflow={{ xs: 'auto' }}
        >
          <Stack
            flexDirection={{ xs: 'row' }}
            columnGap={{ xs: '.5rem' }}
            bgcolor={theme.palette.primary.light}
            borderRadius={3}
            height={{ xs: 250, sm: height - 380 }}
            overflow="hidden"
          >
            {lesson.videoType === VideoTypeEnum.EMBED && (
              <iframe
                width="100%"
                height="auto"
                src={lesson?.videoUrl || ''}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{ border: 'none' }}
              >
              </iframe>
            )}
            {lesson.videoType === VideoTypeEnum.DRIVE && (
              <Stack
                flexDirection={{ xs: 'row' }}
                alignItems={{ xs: 'center' }}
                justifyContent={{ xs: 'center' }}
                width={{ xs: '100%' }}
                onClick={() => window.open(lesson?.videoUrl, '_blank')}
              >
                <Box style={{ cursor: 'pointer' }}>
                  <LoadingImage src={IcDrive} width="300px" height="auto" />
                </Box>
              </Stack>
            )}
          </Stack>
          <Stack
            display={{ xs: 'none', md: 'flex' }}
            flexDirection={{ xs: 'row' }}
            justifyContent={{ xs: 'center' }}
            alignItems={{ xs: 'center' }}
            columnGap={{ xs: '.5rem' }}
            padding={{ xs: '1rem' }}
            bgcolor={theme.palette.primary.light}
            borderRadius={3}
            height={{ xs: 'auto' }}
          >
            <Box color={theme.palette.text.light} sx={{ wordBreak: 'break-word' }}>{lesson?.content || ''}</Box>
          </Stack>
        </Stack>
      )}
      {!lesson?.id && (
        <Box color={theme.palette.text.light} textAlign="center">{t('Let choose video lesson')}</Box>
      )}
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default CourseViewLesson;
