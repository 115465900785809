import { PracticeDto, PracticeListRes } from "models/classes/practice.class"; // Changed import to practice
import { createContext, PropsWithChildren, useContext, useState } from "react";

type State = {
  practiceList: PracticeListRes | null;
  practiceDetail: PracticeDto | null;
};

type Actions = {
  updatePracticeList: (data: PracticeListRes) => void;
  updatePracticeDetail: (data: PracticeDto) => void;
};

const initState: State = {
  practiceList: null,
  practiceDetail: null,
};

const defaultActions: Actions = {
  updatePracticeList: () => { },
  updatePracticeDetail: () => { },
};

const PracticeContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const PracticeProvider = ({ children }: PropsWithChildren<{}>) => {
  const [practiceList, setPracticeList] = useState<PracticeListRes | null>(null);
  const [practiceDetail, setPracticeDetail] = useState<PracticeDto | null>(null);

  const updatePracticeList = (data: PracticeListRes) => {
    setPracticeList(data);
  };

  const updatePracticeDetail = (data: PracticeDto) => {
    setPracticeDetail(data);
  };

  return (
    <PracticeContext.Provider
      value={{
        practiceList,
        practiceDetail,
        updatePracticeList,
        updatePracticeDetail,
      }}
    >
      {children}
    </PracticeContext.Provider>
  );
};

export const usePracticeContext = () => useContext(PracticeContext);
