import { CircularProgress, Stack } from "@mui/material";
import { DARK_THEME, PURPLE_01, WHITE } from "globals/enums/common.enum";
import { PageRouteName } from "globals/enums/routes.enum";
import useRoute from "hooks/useRoute";
import { ReactNode, useEffect, useRef } from "react";
import { useThemeContext } from "store/themeContext";

type Props = {
  isLoading: boolean;
  children: ReactNode;
  height?: string;
  size?: string;
};

const LoadingWrapper = ({
  isLoading,
  children,
  height = "100vh",
  size = "4.5rem",
}: Props) => {
  const { activeTheme } = useThemeContext();
  const { redirect } = useRoute();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isLoading) {
      timeoutRef.current = setTimeout(() => redirect(PageRouteName.DashboardPage), 15000);
    } else if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isLoading, redirect]);

  if (!isLoading) return children;

  return (
    <Stack
      width="100%"
      height={height}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={size} style={{ color: activeTheme === DARK_THEME ? WHITE : PURPLE_01 }} />
    </Stack>
  );
};

export default LoadingWrapper;
