import { Box, MenuItem, Select, SelectProps, useTheme } from "@mui/material";
import { SelectOption } from "globals/constants/types";
import { WHITE } from "globals/enums/common.enum";
import React from "react";

type Props = {
  width?: string | number;
  selectedValue: string | number;
  options: SelectOption[],
  placeHolder: string;
  onChange: (selectedValue: string | number) => void;
} & Omit<SelectProps, 'selectedValue' | 'onChange'>;

const SelectField = React.memo(({
  width = 190,
  options = [],
  selectedValue = '',
  placeHolder = '',
  onChange = () => { },
  ...params
}: Props) => {
  const theme = useTheme();

  return (
    <Box position="relative">
      <Select
        {...params}
        sx={{
          bgcolor: theme.palette.default.light,
          fontFamily: '"Montserrat", sans-serif',
          width: width,
          height: 40.13,
          border: "none !important",
          outline: "none !important",
          color: theme.palette.text.light,
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { border: 0 },
          fontWeight: 300,
          fontSize: 14,
          "& .MuiSvgIcon-root": {
            color: theme.palette.text.light,
          },
        }}
        MenuProps={{
          disableScrollLock: false,
          PaperProps: {
            elevation: 2,
            sx: {
              '&': {
                backgroundColor: theme.palette?.default?.light,
              },
              '.MuiList-padding': {
                padding: 0,
              },
              '& .MuiMenuItem-root': {
                py: 1,
                px: 2,
                fontSize: 14,
                color: theme.palette.text.light,
                fontFamily: '"Montserrat", sans-serif',
              },
              '& .MuiMenuItem-root:hover': {

              },
              '& .MuiMenuItem-root:focus': {
                background: 'linear-gradient(#6C40C9, #804FA0)',
                color: WHITE,
              },
            },
          },
        }}
        value={selectedValue || -1}
        onChange={(e) => onChange && onChange(e.target.value as any)}
      >
        <MenuItem value={-1}>{placeHolder}</MenuItem>
        {(options || []).map(item => (
          <MenuItem value={item.value} key={item.value}>
            {String(item.label).length > 55 ? `${String(item.label).substring(0, 55)}...` : item.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
});

export default SelectField;
