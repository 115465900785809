import React from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Box, Stack } from "@mui/material";
import { WHITE } from "globals/enums/common.enum";

type Props = {
  height: { xs: string, md: string };
  title: string;
  subTitle: string;
  widthTitle?: string;
  imageFile?: string;
  widthImage?: { xs: string, md: string },
  heightImage?: { xs: string }
};

const BannerLayout = React.memo(({
  height,
  title,
  subTitle,
  widthTitle = '60%',
  imageFile,
  widthImage = { xs: '150px', md: '20px' },
  heightImage = { xs: '150px' },
}: Props) => {
  const { t } = useTranslation();

  return (
    <Stack
      className="bg-main"
      width="-webkit-fill-available"
      height={{ xs: '100%', md: height.md }}
      flexDirection="row"
      justifyContent="space-between"
      padding={{ xs: '2rem', md: '0 5rem' }}
      borderRadius="12px"
    >
      <Stack
        flexDirection={{ xs: 'column' }}
        justifyContent={{ xs: 'center' }}
        width={{ xs: '100%', sm: widthTitle }}
        rowGap="1rem"
      >
        <Box
          fontWeight={600}
          fontSize={{ xs: 18, sm: 36 }}
          textTransform="uppercase"
          color={WHITE}
          letterSpacing="1px"
        >{t(title)}</Box>
        <Box fontSize={{ xs: 12, md: 14 }} color={WHITE}>
          {t(subTitle)}
        </Box>
      </Stack>
      {imageFile && (
        <Box display={{ xs: 'none', sm: 'block' }} width={{ xs: widthImage.xs, md: widthImage.md }} height={{ xs: heightImage.xs }}>
          <img src={imageFile} width="100%" height="auto" alt="Ninedev học online" />
        </Box>
      )}
    </Stack>
  );
});

export default BannerLayout;
