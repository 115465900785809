import { Box, Stack } from "@mui/material";
import NinedevIcon from 'assets/ninedev-icon.png';
import { ReactNode } from "react";
import styled from "styled-components";
import LoadingText from "./LoadingText";
import { useTranslation } from "react-i18next";

type Props = {
  isLoading: boolean;
  children: ReactNode;
  height?: string;
};

const LoadingNinedev = ({
  isLoading,
  children,
  height = '100vh',
}: Props) => {
  const { t } = useTranslation();

  return isLoading ? (
    <Stack
      width='100%'
      height={height}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <BoxStyled>
        <img src={NinedevIcon} width="100%" height="auto" />
      </BoxStyled>
      <LoadingText />
    </Stack>
  ) : children
};

const BoxStyled = styled(Box)`
  width: 150px;
  animation: scaleAnimation 2s infinite ease-in-out;

  @keyframes scaleAnimation {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}
`;

export default LoadingNinedev;
