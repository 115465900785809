import { Box } from "@mui/material";
import styled from "styled-components";

const LoadingText = () => {
  return (
    <WrapperStyled>
      <div className="loading loading01">
        <span>N</span>
        <span>I</span>
        <span>N</span>
        <span>E</span>
        <span>D</span>
        <span>E</span>
        <span>V</span>
      </div>
    </WrapperStyled>
  );
};

const WrapperStyled = styled(Box)`
  /* common */
  .loading {
    font-size: 36px;
    font-weight: 800;
    color: ${p => p.theme.palette.accent.main};
    span {
      display: inline-block;
      margin: 0 .5rem;
    }
  }

  /* code #1 */
  .loading01 {
    span {
      animation: loading01 1.4s infinite alternate;
      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.3s;
      }
      &:nth-child(4) {
        animation-delay: 0.4s;
      }
      &:nth-child(5) {
        animation-delay: 0.5s;
      }
      &:nth-child(6) {
        animation-delay: 0.6s;
      }
    }
  }
  @keyframes loading01 {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default LoadingText;
