import BaseModel from "./base.class";

export class PracticeAnswerDto extends BaseModel<PracticeAnswerDto> {
  id?: number;
  name?: string;
  status?: boolean;
  practiceQuestionId?: string;
}

export class PracticeAnswerClientDto extends BaseModel<PracticeAnswerClientDto> {
  id?: number;
  name?: string;
  practiceQuestionId?: string;
}

export type PracticeAnswerListRes = {
  list: PracticeAnswerDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type PracticeAnswerData = {
  name: string;
  status: boolean;
  practiceQuestionId: string;
};
