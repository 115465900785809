import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// ICONS
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import { PageRouteName } from "globals/enums/routes.enum";
import useRoute from "hooks/useRoute";
import { isEmpty } from "lodash";
import { CourseLessonInfoDto } from "models/classes/courseLesson.class";
import { WHITE } from "globals/enums/common.enum";
import { useWindowSize } from "react-use";

type Props = {
  lessons: CourseLessonInfoDto[];
  courseLessonId?: number;
  coursePersonalId?: number;
}

const CourseViewList = React.memo(({ lessons, courseLessonId, coursePersonalId }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { redirect } = useRoute();
  const { height } = useWindowSize();

  const handleViewLesson = (item: CourseLessonInfoDto) => {
    redirect(PageRouteName.CourseViewPage, {
      coursePersonalId,
      courseLessonId: item.id,
    })
  };

  return (
    <WrapperStyled
      width={{ xs: '-webkit-fill-available' }}
      height={{ xs: '100%' }}
      flexDirection="column"
      rowGap={{ xs: '1rem', }}
    >
      <Stack
        padding={{ xs: '1rem 2rem' }}
        className="bg-main"
        borderRadius={3}
      >
        <Box
          fontSize={{ xs: 18, md: 24 }}
          color={WHITE}
          fontWeight={500}
        >
          {t('List of lessons')}
        </Box>
      </Stack>
      <Stack
        flexDirection={{ xs: 'column' }}
        rowGap={{ xs: '1rem' }}
        height={{ xs: height - 175 }}
        overflow={{ xs: 'auto' }}
        className="hide-scroll-auto"
      >
        {(lessons || []).map(item => (
          <Stack
            key={item.id}
            flexDirection={{ xs: 'row' }}
            columnGap={{ xs: '.5rem' }}
            alignItems={{ xs: 'center' }}
            padding={{ xs: '.75rem 1.5rem', md: '1rem 2rem' }}
            bgcolor={theme.palette.primary.light}
            className={courseLessonId === item.id ? 'bg-main' : ''}
            borderRadius={3}
            style={{ cursor: 'pointer' }}
            onClick={() => handleViewLesson(item)}
          >
            <PlayCircleIcon style={{ color: courseLessonId === item.id ? WHITE : theme.palette.text.light }} />
            <Box fontSize={{ xs: 14, md: 16 }} color={courseLessonId === item.id ? WHITE : theme.palette.text.light}>
              {item.name}
            </Box>
          </Stack>
        ))}
        {isEmpty(lessons) && (
          <Box fontSize={{ xs: 14, md: 16 }} color={theme.palette.text.light} textAlign="center">{t('No data')}</Box>
        )}
      </Stack>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default CourseViewList;
