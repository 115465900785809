import { PracticeQuestionDto, PracticeQuestionListRes } from "models/classes/practiceQuestion.class"; // Changed import to practiceQuestion
import { createContext, PropsWithChildren, useContext, useState } from "react";

type State = {
  practiceQuestionList: PracticeQuestionListRes | null;
  practiceQuestionDetail: PracticeQuestionDto | null;
  practiceQuestionClient: PracticeQuestionDto[] | null;
};

type Actions = {
  updatePracticeQuestionList: (data: PracticeQuestionListRes) => void;
  updatePracticeQuestionDetail: (data: PracticeQuestionDto) => void;
  updatePracticeQuestionClient: (data: PracticeQuestionDto[]) => void;
};

const initState: State = {
  practiceQuestionList: null,
  practiceQuestionDetail: null,
  practiceQuestionClient: [],
};

const defaultActions: Actions = {
  updatePracticeQuestionList: () => { },
  updatePracticeQuestionDetail: () => { },
  updatePracticeQuestionClient: () => { },
};

const PracticeQuestionContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const PracticeQuestionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [practiceQuestionList, setPracticeQuestionList] = useState<PracticeQuestionListRes | null>(null);
  const [practiceQuestionDetail, setPracticeQuestionDetail] = useState<PracticeQuestionDto | null>(null);
  const [practiceQuestionClient, setPracticeQuestionClient] = useState<PracticeQuestionDto[]>([]);

  const updatePracticeQuestionList = (data: PracticeQuestionListRes) => {
    setPracticeQuestionList(data);
  };

  const updatePracticeQuestionDetail = (data: PracticeQuestionDto) => {
    setPracticeQuestionDetail(data);
  };

  const updatePracticeQuestionClient = (data: PracticeQuestionDto[]) => {
    setPracticeQuestionClient(data);
  };

  return (
    <PracticeQuestionContext.Provider
      value={{
        practiceQuestionList,
        practiceQuestionDetail,
        practiceQuestionClient,
        updatePracticeQuestionList,
        updatePracticeQuestionDetail,
        updatePracticeQuestionClient,
      }}
    >
      {children}
    </PracticeQuestionContext.Provider>
  );
};

export const usePracticeQuestionContext = () => useContext(PracticeQuestionContext);
