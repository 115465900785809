import { Box, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

// ICONS
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PublicIcon from '@mui/icons-material/Public';
import React from "react";

const ContentJoin = React.memo(() => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Stack
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '1rem' }}
    >
      <Stack flexDirection="row" alignItems="center" columnGap={{ xs: '.5rem' }}>
        <ManageAccountsIcon style={{ fontSize: '24px', color: theme.palette.success.main }} />
        <Box color={theme.palette.success.main} fontStyle="italic" fontSize={{ xs: '14px', md: '15px' }}>
          {t('Please upgrade your account to view content')}
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" columnGap={{ xs: '.5rem' }}>
        <CheckBoxIcon style={{ fontSize: '24px', color: theme.palette.success.main }} />
        <Box color={theme.palette.success.main} fontStyle="italic" fontSize={{ xs: '14px', md: '15px' }}>
          {t('You can participate in all tests')}
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" columnGap={{ xs: '.5rem' }}>
        <CheckBoxIcon style={{ fontSize: '24px', color: theme.palette.success.main }} />
        <Box color={theme.palette.success.main} fontStyle="italic" fontSize={{ xs: '14px', md: '15px' }}>
          {t('You can view the full list of interview questions')}
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" columnGap={{ xs: '.5rem' }}>
        <CardGiftcardIcon style={{ fontSize: '24px', color: theme.palette.warning.main }} />
        <Box color={theme.palette.warning.main} fontStyle="italic" fontSize={{ xs: '14px', md: '15px' }}>
          {t('You will get 1 month free when you buy any course at Ninedev')}
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" columnGap={{ xs: '.5rem' }}>
        <LocalAtmIcon style={{ fontSize: '24px', color: theme.palette.text.dark }} />
        <Box color={theme.palette.text.dark} fontStyle="italic" fontSize={{ xs: '14px', md: '15px' }}>
          {t('Price')}: 120.000đ/{t('month')}
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" columnGap={{ xs: '.5rem' }}>
        <PermContactCalendarIcon style={{ fontSize: '24px', color: theme.palette.text.dark }} />
        <Box color={theme.palette.text.dark} fontStyle="italic" fontSize={{ xs: '14px', md: '15px' }}>
          {t('Contact')}: 0763557366 (Zalo)
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" columnGap={{ xs: '.5rem' }}>
        <FacebookIcon style={{ fontSize: '24px', color: theme.palette.text.dark }} />
        <Box color={theme.palette.text.dark} fontStyle="italic" fontSize={{ xs: '14px', md: '15px' }}>
          {t('Facebook')}: <a href={'https://www.facebook.com/nkt.9920/'} target="_blank" style={{ color: theme.palette.text.light, textDecoration: 'none' }}> 👉 {t('View now')}</a>
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" columnGap={{ xs: '.5rem' }}>
        <PublicIcon style={{ fontSize: '24px', color: theme.palette.text.dark }} />
        <Box color={theme.palette.text.dark} fontStyle="italic" fontSize={{ xs: '14px', md: '15px' }}>
          {t('Fanpage')}: <a href={'https://www.facebook.com/fe.ninedev/'} target="_blank" style={{ color: theme.palette.text.light, textDecoration: 'none' }}> 👉 {t('View now')}</a>
        </Box>
      </Stack>
      <Stack flexDirection="column">
        <Box color={theme.palette.text.dark} fontStyle="italic" fontSize={{ xs: '14px', md: '15px' }}>
          {t('Please contact admin to activate your study')}
        </Box>
        <Box color={theme.palette.text.dark} fontStyle="italic" fontSize={{ xs: '14px', md: '15px' }}>
          {t('and practice account to prepare well for the upcoming interview')}
        </Box>
      </Stack>
    </Stack>
  );
});

export default ContentJoin;