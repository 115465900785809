import useAbortRequest from "hooks/useAbortRequest";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { usePracticeQuestionContext } from "./practiceQuestionContext";
import {
  actionPracticeQuestionClientApi,
  actionPracticeQuestionDetailApi,
  actionPracticeQuestionListApi,
} from "./practiceQuestionAction";
import { FetchPracticeQuestionParams } from "./practiceQuestionParam";

export const usePracticeQuestionListByApi = ({
  searchName,
  page,
}: FetchPracticeQuestionParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { practiceQuestionList, updatePracticeQuestionList } =
    usePracticeQuestionContext();

  const fetchPracticeQuestionListByApi = async () => {
    await abortRequest();
    const data = await actionPracticeQuestionListApi({
      searchName,
      page,
      newAbortSignal,
    });
    updatePracticeQuestionList(data!);
  };

  // Unmount APIs
  useEffect(() => {
    if (isEmpty(practiceQuestionList) || searchName || page) {
      fetchPracticeQuestionListByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page]);

  return {
    practiceQuestionList,
    fetchPracticeQuestionListByApi,
  };
};

export const usePracticeQuestionDetailByApi = ({
  practiceQuestionId,
}: {
  practiceQuestionId: number;
}) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { practiceQuestionDetail, updatePracticeQuestionDetail } =
    usePracticeQuestionContext();

  const fetchPracticeQuestionDetailByApi = async () => {
    await abortRequest();
    const data = await actionPracticeQuestionDetailApi({
      practiceQuestionId,
      newAbortSignal,
    });
    updatePracticeQuestionDetail(data!);
  };

  // Unmount APIs
  useEffect(() => {
    if (practiceQuestionId) {
      fetchPracticeQuestionDetailByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [practiceQuestionId]);

  return {
    practiceQuestionDetail: practiceQuestionId ? practiceQuestionDetail : null,
    fetchPracticeQuestionDetailByApi,
  };
};

export const usePracticeQuestionClient = ({
  practiceSubjectId,
}: FetchPracticeQuestionParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { practiceQuestionClient, updatePracticeQuestionClient } =
    usePracticeQuestionContext();

  const fetchPracticeQuestionClient = async () => {
    await abortRequest();
    const data = await actionPracticeQuestionClientApi({
      practiceSubjectId,
      newAbortSignal,
    });
    updatePracticeQuestionClient(data!);
  };

  // Unmount APIs
  useEffect(() => {
    if (practiceSubjectId) {
      fetchPracticeQuestionClient();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [practiceSubjectId]);

  return {
    practiceQuestionClient,
    fetchPracticeQuestionClient,
  };
};
