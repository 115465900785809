import { isEmpty } from "lodash";
import {
  PracticeSubjectData,
  PracticeSubjectDto,
} from "models/classes/practiceSubject.class";
import {
  fetchPracticeSubjectCreateApi,
  fetchPracticeSubjectDeleteApi,
  fetchPracticeSubjectDetailApi,
  fetchPracticeSubjectListApi,
  fetchPracticeSubjectUpdateApi
} from "./practiceSubjectFetch";
import { FetchPracticeSubjectParams } from "./practiceSubjectParam";

export const actionPracticeSubjectListApi = async ({
  ...params
}: FetchPracticeSubjectParams) => {
  try {
    const { data } = await fetchPracticeSubjectListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: PracticeSubjectDto) =>
          new PracticeSubjectDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeSubjectCreateApi = async (
  body: PracticeSubjectData
) => {
  try {
    const { data } = await fetchPracticeSubjectCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeSubjectUpdateApi = async (
  id: number,
  body: PracticeSubjectData
) => {
  try {
    const { data } = await fetchPracticeSubjectUpdateApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeSubjectDetailApi = async ({
  ...params
}: FetchPracticeSubjectParams) => {
  try {
    const { data } = await fetchPracticeSubjectDetailApi(params);
    if (!isEmpty(data)) {
      return new PracticeSubjectDto().fromPayload(data);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionPracticeSubjectDeleteApi = async ({
  ...params
}: FetchPracticeSubjectParams) => {
  try {
    const { data } = await fetchPracticeSubjectDeleteApi(params);
    return data;
  } catch (error) {
    console.log(error);
  }
};
