import {
  createContext,
  PropsWithChildren,
  useContext,
  useState
} from 'react';

type State = {
  prevRoute: string;
};

type Actions = {
  updatePrevRoute: (prevRoute: string) => void;
};

const initState: State = {
  prevRoute: '',
};

const defaultActions: Actions = {
  updatePrevRoute: () => { }
};

const RouteContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const RouteProvider = ({ children }: PropsWithChildren<{}>) => {
  const [prevRoute, setPrevRoute] = useState(initState.prevRoute);

  const updatePrevRoute = (prevRoute: string) => {
    setPrevRoute(prevRoute);
  };

  return (
    <RouteContext.Provider
      value={{
        prevRoute,
        updatePrevRoute
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};

export const useRouteContext = () => useContext(RouteContext);
